@import '../../../../scss/theme-bootstrap';

.gnav-util-cart {
  min-height: 45px;
  a {
    text-decoration: none;
    color: $color-black;
  }
  &__count {
    color: $color-black;
    font-family: $font--helvetica-neue-bold;
    line-height: 1em;
    position: absolute;
    width: 20px;
    text-align: center;
    top: 58%;
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    @include breakpoint($landscape-up) {
      top: 60%;
    }
  }
  &__content {
    overflow: hidden;
    &-errors {
      @include breakpoint($landscape-up) {
        padding: 0 44px 22px;
        font-size: 14px;
        line-height: get-line-height(12px, 14px);
      }
      ul {
        list-style: none;
        li {
          padding: 5px 0;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    &-loading {
      display: none;
      .gnav-util-cart.cart-loading & {
        height: 20px;
        display: block;
      }
      .loader {
        .gnav-util-cart.cart-loading & {
          display: block;
          opacity: 1;
        }
      }
    }
    &-products {
      display: none;
      font-size: 14px;
      line-height: get-line-height(12px, 12px);
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
      .cart-block--nonempty.cart-loading & {
        display: none;
      }
      &-no-items {
        display: none;
        .cart-block--empty & {
          display: block;
          padding: 0 0 20px;
        }
        .cart-block--empty.cart-loading &,
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &-details {
        overflow-y: auto;
        max-height: 300px;
        line-height: get-line-height(12px, 18px);
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $color-black;
        }
      }
      &-bottom {
        padding-bottom: 20px;
        &-subtotal {
          padding: 10px 0;
          border-top: 1px solid $color-primary-900;
          text-align: center;
          font-size: 12px;
          color: $color-primary-900;
          display: flex;
          justify-content: space-between;
          &-details-value {
            font-family: $font--helvetica-neue-bold;
            padding-#{$ldirection}: 10px;
          }
        }
        &-checkout {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          a.gnav-util-cart__content-products-checkout-button {
            color: $color-white;
            width: 120px;
            text-align: center;
            margin: 10px 0;
          }
        }
        &-offer {
          font-family: $font--helvetica-neue-bold;
          font-size: 12px;
        }
      }
    }
  }
  &.cart-block--empty {
    .gnav-util__icon__cart-count {
      display: none;
    }
  }
}
