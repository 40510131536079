///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand

// invoke in scss as example: #{$base-theme-path}
$base-theme-path: '/sites/labseries/themes/labseries_base';
$img-base-url: '../img/';
$netstorage-fonts-path: '/_fonts/labseries'; // Netstorage path

// Breakpoints
$bp-medium: 751px !default;
$bp-large: 1367px !default;

// Layout
$max-width: 960px !default; // this is way too small - breaking 2021 redesign
$max-width-cr21: 1920px !default;
$header-height: 46px !default; // used to offset anchors, etc.
$mobile-header-height: 143px !default;
$mobile-sticky-header-height: 88px !default;
$admin-toolbar-height: 61px !default;
$desktop-min-width: 1366px !default;

// Directions
$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

// Fonts: Family
$font--serif: serif !default;
$font--sans: sans-serif !default;
$font--mono: monospace !default;

// Base font size and line height
$primary-font-size: 16px !default;
$primary-line-height: 1.2 !default;
$primary-letter-spacing: -0.3px !default;
$base-letter-spacing: -0.3px !default;

// Fonts: Helvetica Neue fonts
$font--helvetica-neue-light: 'Helvetica Neue Light', Helvetica, sans-serif !default;
$font--helvetica-neue-regular: 'Helvetica Neue Regular', Helvetica, sans-serif !default;
$font--helvetica-neue-bold: 'Helvetica Neue Bold', Helvetica, sans-serif !default;
$font--helvetica-neue: $font--helvetica-neue-regular; // REMOVE LATER; only for preventing gulp break on gnav / search

// Fonts: Global Re-use
$font--text: $font--helvetica-neue-regular;
$font--heading: $font--helvetica-neue-bold;

// Base font size and line height
$base-font-size: 16px !default;
$base-line-height: 1.6 !default;
$base-letter-spacing: -0.3px !default;

// Typography
$primary-font: $font--helvetica-neue-regular;
$header-font: $primary-font;
$body-font-family: $primary-font;
$body-font-size: 12px;
$body-line-height: 1.45em !default;
$product-title-font-size: 18px !default;
$product-subline-font-size: 14px !default;
$button-line-height: 35px !default;
$cart-header-line-height: 2 !default;

// Primary Colors
$color-broken-black: #111111;
$color-sugartone: #ededed;
$color-lighter-gray-2: #eaeaea !default;
$color-max-ls: #4b4f54;

// Secondary Colors
$color-black: #000000;
$color-white: #ffffff;

// Primary grey color types
$color-primary-900: #212121;
$color-primary-800: #424242;
$color-primary-700: #616161;
$color-primary-600: #757575;
$color-primary-500: #9e9e9e;
$color-primary-400: #bdbdbd;
$color-primary-300: #e0e0e0;
$color-primary-200: #eeeeee;
$color-primary-100: #f5f5f5;
$color-primary-50: #fafafa;

// Colors (http://sachagreif.com/sass-color-variables/)
$color-normal-grey: #cccccc !default;
$color-grey: #dbdbdb !default;
$color-gray: $color-grey !default;
$color-lighter-gray: $color-grey !default;
$color-light-gray: $color-grey !default;
$color-medium-gray: #949599 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-light-grey: $color-light-gray !default;
$color-medium-grey: $color-medium-gray !default;
$color-dark-gray: #7b7b7b !default;
$color-dark-grey: $color-dark-gray !default;
$color-pink: #ff4661 !default;
$color-red: #ff0000 !default;
$color-light-pink: #ffcccc !default;
$color-dark-pink: #cc4661 !default;
$color-light-brown: #f7f4ef !default;
$color-blue: #003da6 !default;
$color-bright-blue: $color-blue !default;
$color-medium-blue: #5bb4e5 !default;
$color-dark-blue: #005288 !default;
$color-text: $color-black !default;
$color-link: $color-blue !default;
$color-link-hover: $color-black !default;
$black: $color-black !default;
$white: $color-white !default;
$transparent--white: rgba(255, 255, 255, 0.75);
$color-transparent: none transparent;
$color-shimmer-grey: #edeef1;
$color-shimmer-grey-light: #f6f7f8;
$color-black-op88: rgba(0, 0, 0, 0.88);

// Lab Series section colors for Gnav, MPP, SPP
$color-best-seller: #949599 !default; // BEST SELLER
$color-clean: #5bb4e5 !default; // CLEAN
$color-shave: #00833d !default; // SHAVE
$color-hair-body: #c23832 !default; // HAIR/BODY
$color-tone: #1f5cb3 !default; // TONE
$border-thick-width: 1px;
$color-dark-red: #b50404;
$color-light-blue: #5c99c4;
$color-light-grey-tone: #b9b9bb;

// common variables
$border: $black solid 1px !default;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatibility mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";

// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;

// Search
// @todo cleanup
$mobile_search_header_height: 60px;
$pc-search-width: 551px;
$color-gray-medium: #8f8f8f;
$color-gray-light: #666666;
$color-gray-dark: #333333;
$color-blue-dark: #026585;
$color-dark-red: #b50404;
$color-light-blue: #5c99c4;
$color-grey-border: #8c8c8d;
$color-san-marino: #3e65a6;

// To keep our paddings consistent
$header-width: 960px;
$header-max-width: 100%;
$header-gray: #ccc;
$header-background: #e5e5e5;

// Password Reset
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$tick: '\2713';
$cross: '\2715';
$color-success: #458745 !default;
$color-green: #090 !default;
$color-error: $color-dark-red;

// System colors
$color-focus: #54a6d7;

$color-pink-2: #fff5f5;

// Imported from Genie: Max widths for formatters and content containers
$container-max-width: 1920px !default;
$container-max-width-landscape: 1440px !default;

// Content Blocks.
$sitewide-banner-height: 70px !default;
$sitewide-banner-mobile-height: 125px !default;
$content-block-signup-height: $sitewide-banner-height;
$content-block-signup-mobile-height: $sitewide-banner-mobile-height;

// Imported from Genie: Gnav specific vars
$gnav-max-width: $container-max-width;
$gnav-fixed-z-index: 995 !default;
$navbar-mobile-height: 51px !default;
$navbar-height: 62px !default;
$second-navbar-mobile-height: 40px !default;
$utility-nav-height: 95px + 28px !default;
$offerbar-height: 38px !default;
$offerbar-mobile-height: 48px !default;
$gnav-mobile-height: $navbar-mobile-height + $offerbar-mobile-height;
$gnav-height: $utility-nav-height + $navbar-height + $offerbar-height;
$toolbar-height: 87px;
$gnav-icon--inline: 14px !default;
$gnav-mobile-hpad--outer: 22px !default;
$gnav-mobile-link-height: 60px !default;
$gnav-content-max-height: 220px !default;

// Imported from Genie: Content containers outer pad vars
$container-pad-small: 20px !default;
$container-pad-landscape: 20px !default;

// Power Reviews specific
$pr-rating-suffix: ' out of 5' !default;
$pr-pseudo-text-yes: 'yes' !default;
$pr-pseudo-text-no: 'no' !default;
$pr-med-large-up: 840px;

// SCSS variable configuration to include styles for navigation creative refresh 2021.
$cr21_nav_refresh: false !default;

$color-black-light: #191919;
$color-dark-navy: #040a2b;
$color-gray-bg: #f5f5f5;
$color-grey-light: #eff1f0;
$color-navy-blue: #0075db;
$color-off-black: #120e02 !default;
$color-pink-swan: #b3b3b3;
$color-nobel: #999999;
$color-warm-grey: #777777;
$light-gray: #cdcbc9 !default;
$lux-error: #dc3530 !default;
$lux-error-opacity-05: rgba($lux-error, 0.05);

// Offer Message Color
$color-success-message: #087443;
$color-error-message: #a80000;
$color-alert-message: #b54708;

// Hide the Background img checkout
$hide_header_img: false !default;